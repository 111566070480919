/* website: 3382-spinelliinfinity1
 * created at 2021-05-13 4:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/infiniti.scss";
@import "../utils/icons.scss";

.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__infiniti-spinelli-pointe-claire{
        .listing-tile .listing-tile-image .car-info{
            position: relative;
        }
        .listing-tile .listing-tile-image{
            display:flex;
            flex-direction: column-reverse;
        }
        .listing-tile .listing-tile-image .car-clearance{
            bottom: unset;
            top: 50px;
            left: 20px;
            z-index: 1;
            border-radius:2px;
            font-weight: 700;
            line-height: 20px;
            padding: 3px 8px;
        }
        .checkout__section-delivery input[type=radio]+label{
            text-transform: unset !important;
        }
        .showroom-build-and-price{
            margin-bottom:0;
        }
        /** fix place tradein at top of listing **/
        .listing-used-car-search>div:last-child{
            display: flex;
            flex-direction: column;
            #instant-estimate-bar-wrapper{
                order: 0;
                margin-top: 0px !important;
            }
            .listing-tiles{
                order: 1;
            }
            .listing-used-button-loading.sr-button-1{
                order: 2;
            }
        }
            /** fix AV widget padding on V2 VLP **/
        .listing-used-car-search{
            .av_widget_root{
                margin: 0 0 20px 0 !important;
            }
        }
    }
}

:lang(fr) .widget-sr{
    &.dealer__infiniti-spinelli-pointe-claire{
        .TransactionSummary--wrapper--title{
            span{
                display: none;
            }
            &:after{
                content: 'Votre transaction';
                font-family: 'Infiniti Primary Bold' ,sans-serif;
                font-weight: 700;
                align-self: center;
            }
        }
        #button-register{
            .label{
                display: none;
            }
            &:after{
                content:'Créez un compte';
            }
        }
    }
}